var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.currentUser)?_c('v-dialog',{attrs:{"scrollable":"","width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"color":"secondary","tile":"","disabled":_vm.currentUser.store.transbankCodeApplied}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.currentUser.store.transbankCodeApplied ? 'Código ya gestionado' : 'Gestionar código de comercio')+" ")])]}}],null,false,764113382),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Formulario de solicitud "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-subtitle',{staticClass:"text-subtitle-1 mt-3"},[_vm._v(" Para comenzar con los trámites para dar de alta tu código de comercio en Transbank vamos a necesitar solicitarte una serie de datos personales. "),_c('span',{staticClass:"font-weight-medium"},[_vm._v("Estos datos no se almacenan y son dirigidos directamente a un representante comercial de Transbank")]),_vm._v(". "),_c('br'),_vm._v(" Una vez enviado el formulario, un asesor de Transbank se pondrá en contacto con usted para finalizar el proceso. Esto puede demorar entre 24hs a 48hs como máximo. ")]),_c('v-divider'),_c('v-card-text',{staticClass:"text-subtitle-1 mt-3"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-btn-toggle',{staticClass:"fill-width mb-3 d-flex flex-column flex-md-row",attrs:{"color":"primary","tile":"","rules":[
          v => !!v || 'Campo obligatorio'
          ]},model:{value:(_vm.accountType),callback:function ($$v) {_vm.accountType=$$v},expression:"accountType"}},[_c('v-btn',{staticClass:"py-4",attrs:{"value":"single"}},[_vm._v(" Soy persona natural ")]),_c('v-btn',{staticClass:"py-4",style:(_vm.$vuetify.breakpoint.mobile ? 'border-left-width: 1px !important; margin-top: -2px !important' : ''),attrs:{"value":"company"}},[_vm._v(" Soy empresa ")])],1),(_vm.accountType)?[_c('v-text-field',{attrs:{"filled":"","label":"Nombre completo","rules":[
            v => !!v || 'Campo obligatorio'
            ]},model:{value:(_vm.attributes.fullname),callback:function ($$v) {_vm.$set(_vm.attributes, "fullname", $$v)},expression:"attributes.fullname"}}),_c('v-text-field',{attrs:{"filled":"","label":_vm.$t('transbank.rut'),"rules":[
            v => !!v || 'Campo obligatorio'
            ]},model:{value:(_vm.attributes.rut),callback:function ($$v) {_vm.$set(_vm.attributes, "rut", $$v)},expression:"attributes.rut"}}),(_vm.accountType == 'company')?[_c('v-file-input',{attrs:{"filled":"","accept":"image/*","label":"Extracto de la sociedad donde indique al representante legal"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_vm._l((['rutRep', 'serialNumber', 'fantasyName']),function(field){return _c('v-text-field',{attrs:{"filled":"","label":_vm.$t('transbank.' + field),"rules":[
              v => !!v || 'Campo obligatorio'
              ]},model:{value:(_vm.attributes[field]),callback:function ($$v) {_vm.$set(_vm.attributes, field, $$v)},expression:"attributes[field]"}})})]:_vm._e(),_c('vuetify-google-autocomplete',{attrs:{"filled":"","id":"map","label":"Dirección","persistent-hint":"","no-filter":"","rules":[
            v => !!v || 'Campo obligatorio'
            ]},model:{value:(_vm.attributes.address),callback:function ($$v) {_vm.$set(_vm.attributes, "address", $$v)},expression:"attributes.address"}}),_vm._l((['phone', 'email', 'bank', 'bankAccountType', 'bankAccountNumber']),function(field){return _c('v-text-field',{attrs:{"filled":"","label":_vm.$t('transbank.' + field),"rules":[
            v => !!v || 'Campo obligatorio'
            ]},model:{value:(_vm.attributes[field]),callback:function ($$v) {_vm.$set(_vm.attributes, field, $$v)},expression:"attributes[field]"}})})]:_vm._e()],2)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":!_vm.accountType || _vm.loading,"loading":_vm.loading},on:{"click":_vm.send}},[_vm._v(" Enviar ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }